import React, { Component } from "react";

import { Link } from "react-router-dom";

import Spinner from "../../../common/spinner/Spinner";

import DataTable, {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableContainer,
  TableCell
} from "carbon-components-react/lib/components/DataTable/";
import { Information32 } from "@carbon/icons-react";

import axios from "axios";
import { Introduction } from "./components/Introduction";
import NavigateButton from "../../../common/elements/NavigateButton";
import { Pagination } from "carbon-components-react";
import { userActions } from "../../../../_actions";
import { connect } from "react-redux";
import { notifyError } from "../../../common/notification/NotificationHelper";
import { history } from "../../../../_helpers";
import CountrySelection from "../../../common/CountrySelection";


class TableSDG1672 extends Component {
  constructor(props) {
    super(props);
    this.handleViewData = this.handleViewData.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);

  }
  state = {
    initialRows: [],
    loading: true
  };

  componentDidMount() {

    let selectedCountry = localStorage.getItem("selectedCountry");

    const url = selectedCountry && selectedCountry !== "" && selectedCountry !=="999" 
                  ? "/api/v2/sdg1672/?country=" + selectedCountry 
                  : "/api/v2/sdg1672/?limit=10&offset=0" ;

    axios.get(url).then(response => {
      let data = [];
      response.data.results.forEach(indicator => {
        data.push({
          id: indicator.id.toString(),
          country_name: indicator.country_name,
          year: indicator.year,
          modified_date: indicator.modified_date,
          finalized: indicator.status !== 'entry' ? "YES" : "NO",
          modified_ogc: indicator.modified_by_OGC === true ? "YES" : "NO",
          validated: indicator.status === 'validated' ? "YES" : "NO"
        });
      });
      this.setState({
        item_count: response.data.count,
        initialRows: data,
        loading: false,
        current_page: 1,
        page_size: 10
      });
    })
    .catch(error => {
      this.setState({loading: false});
      if (error.response.status === 401) {
        notifyError("Session expired. Please login again!")
        const { dispatch } = this.props;
        dispatch(userActions.logout());
      } else {
        notifyError(
          "Server not available at the moment. Please try again."
        );
        history.push("/");
      }
    });
  }

  onSelectionChanged(selectedValue) {
    //console.log("selected value");
    const url = selectedValue === "999" ? "/api/v2/sdg1672/" : "/api/v2/sdg1672/?country=" + selectedValue;

    axios.get(url).then((response) => {
      let data = [];
      response.data.results.forEach(indicator => {
        data.push({
          id: indicator.id.toString(),
          country_name: indicator.country_name,
          year: indicator.year,
          modified_date: indicator.modified_date,
          finalized: indicator.status !== 'entry' ? "YES" : "NO",
          modified_ogc: indicator.modified_by_OGC === true ? "YES" : "NO",
          validated: indicator.status === 'validated' ? "YES" : "NO"
        });
      });
      this.setState({
        item_count: response.data.count,
        initialRows: data,
        loading: false,
      });
    });
  }

  handleViewData(e) {
    return "";
  }

  onPaginationChange = data => {
    let limit = data.pageSize;
    let offset = (data.page - 1) * data.pageSize;
    let page = data.page;

    axios
      .get("/api/v2/sdg1672/?limit=" + limit + "&offset=" + offset)
      .then(response => {
        let data = [];
        response.data.results.forEach(indicator => {
          data.push({
            id: indicator.id.toString(),
            country_name: indicator.country_name,
            year: indicator.year,
            modified_date: indicator.modified_date,
            finalized: indicator.status !== 'entry' ? "YES" : "NO",
            modified_ogc: indicator.modified_by_OGC === true ? "YES" : "NO",
            validated: indicator.status === 'validated' ? "YES" : "NO"
          });
        });
        this.setState({
          item_count: response.data.count,
          initialRows: data,
          loading: false,
          current_page: page,
          page_size: limit
        });
      })
      .catch(error => {
        this.setState({loading: false});
        if (error.response.status === 401) {
          notifyError("Session expired. Please login again!")
          const { dispatch } = this.props;
          dispatch(userActions.logout());
        } else {
          notifyError(
            "Server not available at the moment. Please try again."
          );
          history.push("/");
        }
      });
  };

  render() {
    const headers = [
      {
        key: "country_name",
        header: "Country"
      },
      {
        key: "year",
        header: "Year"
      },
      {
        key: "modified_date",
        header: "Last Edited Date"
      },
      {
        key: "finalized",
        header: "Inserted by the country"
      },
      {
        key: "modified_ogc",
        header: "Inserted by UNDP"
      },  
      {
        key: "validated",
        header: "Validated/Locked"
      },
      {
        key: "val",
        header: "Add / View Data"
      }
    ];

    let countrySelection =
    this.props.user && this.props.user.user_type === "OGC" ? (
      <div className="box">
        <div style={{ padding: "15px" }}>
          <CountrySelection addAll={true} onSelectionChanged={this.onSelectionChanged} />
        </div>
      </div>
    ) : null;

    let dataTable;

    if (this.state.loading) {
      dataTable = <Spinner />;
    } else {
      dataTable = (
        <DataTable
          headers={headers}
          rows={this.state.initialRows}
          render={({ rows, headers, getRowProps }) => (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map(header =>
                      header.key === "country_name" ? (
                        <TableHeader key={header.key} style={{ width: "20%" }}>
                          {header.header}
                        </TableHeader>
                      ) : header.key === "val" ? (
                        <TableHeader key={header.key} style={{ width: "25%" }}>
                          {header.header}
                        </TableHeader>
                      ) : (
                        <TableHeader key={header.key}>
                          {header.header}
                        </TableHeader>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {console.log("rows", rows)}
                  {rows.map((row, index) => {
                    return (
                      <TableRow {...getRowProps({ row })}>
                        {
                        console.log(this.state.initialRows[index])  
                        }
                        {
                        this.state.initialRows[index] && Object.keys(this.state.initialRows[index])
                          .filter(key => key !== "id")
                          .map(key => {
                            return (
                              <TableCell key={row.id + ":" + key}>
                                {this.state.initialRows[index][key]}
                              </TableCell>
                            );
                          })}
                        <TableCell>
                          {this.state.initialRows[index] && this.state.initialRows[index]["validated"] ===
                          "YES" ? (
                            <Link to={`/sdg1672/${row.id}`}>Click to view</Link>
                          ) : (
                            <NavigateButton
                              to={`/sdg1672/${row.id}`}
                              title="Add / View Data"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
      );
    }

    let pagination =
      this.state.item_count > 10 ? (
        <div>
          <Pagination
            backwardText="Previous page"
            disabled={false}
            forwardText="Next page"
            isLastPage={false}
            // itemRangeText={function noRefCheck() {}}
            // itemText={function noRefCheck() {}}
            itemsPerPageText="Items per page:"
            onChange={this.onPaginationChange}
            page={this.state.current_page}
            pageInputDisabled={false}
            pageNumberText="Page Number"
            pageRangeText={function noRefCheck() {}}
            pageSize={this.state.page_size}
            pageSizes={[10, 20, 30, 40, 50]}
            pageText={function noRefCheck() {}}
            pagesUnknown={false}
            totalItems={this.state.item_count}
          />
        </div>
      ) : null;

    return (
      <div id="content-wrapper" className="content-wrapper">
        <section className="indicator-header" style={{ display: "flex" }}>
          <img
            src="/resources/img/Icon_16-7-2.png"
            style={{ height: "55px", marginRight: "10px" }}
            alt="1672 icon"
          />
          <h1>SDG 16.7.2: Inclusive and responsive decision-making</h1>
          <div style={{ paddingTop: "10px" }}>
            <a
              title="Click to see indicator brief"
              target="_blank"
              rel="noopener noreferrer"
              href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-07-02.pdf"
            >
              <Information32 />
            </a>
          </div>
        </section>
        <div className="box introduction-box">
          <div className="box-body">
            <Introduction />
          </div>
        </div>
        <div className="box">
          <div className="box-header with-border">
            <div className="col-sm-8">
              <h3>SDG 16.7.2 data</h3>
            </div>
          </div>
          <div className="box-body">
          {countrySelection}

            {pagination}
            {dataTable}
            {pagination}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user,
  };
}


export default connect(mapStateToProps)(TableSDG1672);
