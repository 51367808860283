import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../../_actions";
import { NumberInput } from "carbon-components-react";


class RowRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleTableChange(e) {
    // console.log("name", e.target.name, e.target.value)
    this.props.handleTableChange(e.target.name,e.target.value);
  }


  render() {

    console.log("update", this.props.name, this.props.data[0][this.props.name], this.props.data[1][this.props.name], this.props.data[2][this.props.name])
    return (
      <React.Fragment>
        <td>{this.props.title}</td>
        <td>
          <NumberInput
            id={`1:${this.props.name}`}
            name={`1:${this.props.name}`}
            // className="input-1671b"
            style={{fontSize:"0.95vw"}}
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data[1][this.props.name]  !== null
                ? parseInt(this.props.data[1][this.props.name])
                : ""
            }
            allowEmpty={true}
          />
        </td>
        <td>
          <NumberInput
            id={`0:${this.props.name}`}
            name={`0:${this.props.name}`}
            // className="input-1671b"
            style={{fontSize:"0.95vw"}}
            labelText=""
            onChange={this.handleTableChange}
            value={
              this.props.data && this.props.data[0][this.props.name]  !== null
                ? parseInt(this.props.data[0][this.props.name])
                : ""
            }
            allowEmpty={true}
          />
        </td>
        <td>
          <NumberInput
            id={`2:${this.props.name}`}
            name={`2:${this.props.name}`}
            // className="input-1671b"
            style={{fontSize:"0.95vw"}}
            labelText=""
            disabled={!isNaN(parseInt(this.props.data[0][this.props.name])) || !isNaN(parseInt(this.props.data[1][this.props.name]))}
            onChange={this.handleTableChange}
            invalidText=""
            value={
              this.props.data && this.props.data[2][this.props.name] !== null
                ? this.props.data[2][this.props.name]
                : ""
            }
            allowEmpty={true}
          />
        </td>
      </React.Fragment>
    )
  }

}


class RawDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
    this.updateTotalNumber = this.updateTotalNumber.bind(this);

  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    // console.log("Target", target);

    if (parseInt(target[0]) === 2) {
      
      dispatch(
        sdg1633Actions.updateDataField(parseInt(target[0]), target[1], parseFloat(value))
      );
    }
    else {
      dispatch(
        sdg1633Actions.updateDataField(parseInt(target[0]), target[1], parseInt(value))
      );
    }


    if (parseInt(target[0]) === 1) {
      if (!isNaN(parseInt(value))) {
        if (!isNaN(this.props.data.data[0][target[1]])) {
          this.updateTotalNumber(2, target[1], (parseInt(value)*100/this.props.data.data[0][target[1]]).toFixed(2))
        }
      }
      else if (!isNaN(this.props.data.data[0][target[1]])) {
        this.updateTotalNumber(2, target[1], null)
      }
    }
    else if(parseInt(target[0]) === 0) {
      if (!isNaN(parseInt(value))) {
        if (!isNaN(this.props.data.data[1][target[1]])) {
          this.updateTotalNumber(2, target[1], (this.props.data.data[1][target[1]]*100/parseInt(value)).toFixed(2))
        }
      }
      else if (!isNaN(this.props.data.data[1][target[1]])) {
        this.updateTotalNumber(2, target[1], null)
      }
    }

  }

  updateTotalNumber(index, name, value) {
    let { dispatch } = this.props;

    console.log("dispatch");

    dispatch(
      sdg1633Actions.updateDataField(index, name, value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  render() {

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={!this.props.data.id || this.props.data.id === null}
              >
                <table className="table table-bordered table-1671b">
                  <thead style={{fontSize:"0.95vw"}}>
                    <tr>
                      <th style={{ width: "55%" }}></th>
                      <th>Numerator: Number  of people who experienced a dispute during the past two years who accessed a formal or informal dispute resolution mechanism (b)</th>
                      <th>Denominator: Number of people who experienced a dispute in the past two years, minus those who are voluntarily self-excluded (e)</th>
                      <th>[ Numerator / Denominator ] * 100</th>
                    </tr>
                  </thead>
                  <tbody  style={{fontSize:"0.95vw"}}>
                    <tr>
                      <RowRenderer 
                        title="Total/National"
                        name="total"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <td colSpan="4" style={{fontWeight:"bold"}}>Sex</td>
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Male"
                        name="male"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Female"
                        name="female"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <td colSpan="4" style={{fontWeight:"bold"}}>Disability status</td>
                    </tr>                    
                    <tr>
                      <RowRenderer 
                        title="Disabled"
                        name="disabled"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Not disabled"
                        name="not_disabled"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <td colSpan="4" style={{fontWeight:"bold"}}>Ethnicity</td>
                    </tr>                    
                    <tr>
                      <RowRenderer 
                        title="Any non-majority ethnicity:"
                        name="non_majority"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Ethnic group A:"
                        name="ethnic_group_a"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <RowRenderer 
                        title="Ethnic group B:"
                        name="ethnic_group_b"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <RowRenderer 
                        title="Ethnic group C:"
                        name="ethnic_group_c"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <td colSpan="4" style={{fontWeight:"bold"}}>Migration background</td>
                    </tr>                    
                    <tr>
                      <RowRenderer 
                        title="Any migration background:"
                        name="migration_background"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Background A:"
                        name="background_a"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <RowRenderer 
                        title="Background B:"
                        name="background_b"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <RowRenderer 
                        title="Background C:"
                        name="background_c"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <td colSpan="4" style={{fontWeight:"bold"}}>Citizenship</td>
                    </tr>                    
                    <tr>
                      <RowRenderer 
                        title="Any non-national:"
                        name="non_national"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>
                    <tr>
                      <RowRenderer 
                        title="Nationality A:"
                        name="nationality_a"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <RowRenderer 
                        title="Nationality B:"
                        name="nationality_b"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                    <tr>
                      <RowRenderer 
                        title="Nationality C:"
                        name="nationality_c"
                        handleTableChange={this.handleTableChange}
                        data={this.props.data.data && this.props.data.data}
                      />
                    </tr>                  
                  
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1633State;

  return {
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(RawDataTable);

export { connectedIndicatorData as RawDataTable };
