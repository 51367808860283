import React, { Component } from "react";
import { connect } from "react-redux";

import { sdg1672Actions } from "../../../../../_actions";

class Introduction extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  state = {};

  handleChange(e) {
    const { dispatch } = this.props;

    dispatch(sdg1672Actions.updateMetadataField(e.target.name, e.target.value));
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="row" style={{ display: "block" }}> */}
        <div className="box-header with-border">
        <div className="col-sm-12" style={{display:"flex", justifyContent:"space-between"}}>
            <h3>Introduction</h3>
            <img
              src="/resources/img/undp-logo-30.svg"
              style={{ height: "55px", marginLeft: "60px" }}
              alt="undp logo"
            />
          </div>
        </div>
        <div className="box-body">
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-sm-12">
              <h5>
                SDG 16.7.2: Proportions of population who believe
                decision-making is inclusive and responsive, by various
                characteristics
              </h5>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-lg-6">
              <img src="/resources/img/sdg16_7_2.jpg" className="intro-image" alt="SDG 1672" />
              <div style={{ fontSize: "10px" }}>
                Image credit UNDP Kenya / Kevin Ouma
              </div>
            </div>
            <div className="col-lg-6">
              <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                SDG indicator 16.7.2 measures the extent to which people think
                that politicians and/or political institutions will listen to,
                and act on, the opinions of ordinary citizens. The indicator is
                usually sourced from nationally representative household
                surveys.
              </div>
              <div className="col-sm-12" style={{ marginBottom: "10px" }}>
                To address both dimensions covered by this indicator, two
                well-established survey questions are used:
                <ul style={{ listStyle: "inherit", paddingLeft: "50px" }}>
                  <li>
                    Inclusive decision-making: “How much would you say the
                    political system in [country X] allows people like you to
                    have a say in what the government does?”
                  </li>
                  <li>
                    Responsive decision-making: “And how much would you say that
                    the political system in [country] allows people like you to
                    have an influence on politics?”
                  </li>
                </ul>
              </div>
              <div className="col-sm-12">
                To derive a single composite estimate for this indicator, the
                share of people who responded positively (i.e. the
                percentage of respondents who responded 3, 4 or 5 on the
                recommended 5-point scale) is calculated for each question,
                followed by a simple average of these two percentages.
              </div>
            </div>
          </div>
          {/* <div className="row">
            To derive a single composite estimate for this indicator, the share
            of respondents who responded positively (i.e. the percentage of
            respondents who responded 3, 4 or 5 on the recommended 5-point
            scale) is calculated for each question, followed by a simple average
            of these two percentages.
          </div> */}
          <div className="row">
            <div className="col-sm-12">
              Please read carefully the full&nbsp;
              <a
                href="https://unstats.un.org/sdgs/metadata/files/Metadata-16-07-02.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                metadata&nbsp;
              </a>
              for this indicator before submitting your data.
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12">
              A PDF version of the data reporting form for this indicator can be
              downloaded <a target="_blank" href="/resources/files/16.7.2.pdf">here</a>. This PDF form is only for NSOs’ internal
              documentation and not a mandatory requirement of the reporting
              process.
            </div>
          </div>        
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1672State;

  return {
    data,
    error,
  };
}

const connectedIntroduction = connect(mapStateToProps)(Introduction);
export { connectedIntroduction as Introduction };
