import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671cActions } from "../../../../../_actions";
import { Link } from "react-router-dom";
import { RawDataTable } from "./data/RawDataTable";
import { ProportionsTable } from "./data/ProportionsTable";
import { RatiosTable } from "./data/RatiosTable";

class IndicatorDataV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    // console.log("Target", target);

    dispatch(
      sdg1671cActions.updateDataField(parseInt(target[0]), target[1], value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  render() {
    let info = this.props.data &&
      (!this.props.data.id || this.props.data.id === null) ? (
        <div className="row">
          <div className="col-sm-12" style={{ marginBottom: "15px" }}>
            * Please save <Link onClick={this.goToMetadata}>metadata</Link>{" "}
            before entering indicator data.
          </div>
        </div>
      ) : null;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <div className="col-sm-8">
            <h3>Data fields – please provide weighted estimates</h3>
          </div>
        </div>
        <div className="box-body">
          {info}
          <RawDataTable />
          <ProportionsTable />
          <RatiosTable />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { data, error } = state.sdg1671cState;

  return {
    user,
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(IndicatorDataV2);

export { connectedIndicatorData as IndicatorDataV2 };
