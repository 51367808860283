import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../../_actions";
import InputTable from "../../../../../common/table/InputTable";


class DisputeResolutionTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    dispatch(
      sdg1633Actions.updateMetadataField(name, parseInt(value))
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  
  render() {

    let headers = [
      {title: "", width: "8%"},
      {title: "Types of dispute resolution mechanisms", width: "75%"},
      {title: "Number of people who accessed the dispute resolution mechanism for the selected dispute"}
    ]

    let tableData = [
      {
        index : 1,
        data : this.props.data,
        texts: [
          {title: "A.", 
          width: "8%"},  
          {title:"Court or tribunal",}
        ],
        inputs: [
          {
            name: "court_tribunal_accessed",
            data : this.props.data ? this.props.data["court_tribunal_accessed"]: "",
          }
        ]       
      },
      {
        index : 2,
        data : this.props.data,
        texts: [
          {title: "B.", 
          width: "8%"},  
          {title:"Police (or other law enforcement)",}
        ],
        inputs: [
          {
            name: "police_accessed",
            data : this.props.data ? this.props.data["police_accessed"]: "",
          }
        ]         
      },
      {
        index : 3,
        data : this.props.data,
        texts: [
          {title: "C.", 
          width: "8%"},  
          {title:"A government or municipal office or other formal designated authority or agency",}
        ],
        inputs: [
          {
            name: "other_formal_authority_accessed",
            data : this.props.data ? this.props.data["other_formal_authority_accessed"]: "",
          }
        ]         
      },
      {
        index : 4,
        data : this.props.data,
        texts: [
          {title: "D.", 
          width: "8%"},  
          {title:"Religious leader or authority",}
        ],
        inputs: [
          {
            name: "religious_leader_accessed",
            data : this.props.data ? this.props.data["religious_leader_accessed"]: "",
          }
        ]         
      },
      {
        index : 5,
        data : this.props.data,
        texts: [
          {title: "E.", 
          width: "8%"},  
          {title:"Community leader or authority (such as village elder, or local leader)",}
        ],
        inputs: [
          {
            name: "communitu_leader_accessed",
            data : this.props.data ? this.props.data["communitu_leader_accessed"]: "",
          }
        ]         
      },
      {
        index : 6,
        data : this.props.data,
        texts: [
          {title: "F.", 
          width: "8%"},  
          {title:"Lawyer, solicitor, paralegal",}
        ],
        inputs: [
          {
            name: "lawyer_solicitor_paralegal_accessed",
            data : this.props.data ? this.props.data["lawyer_solicitor_paralegal_accessed"]: "",
          }
        ]         
      },
      {
        index : 7,
        data : this.props.data,
        texts: [
          {title: "G.", 
          width: "8%"},  
          {title:"Other formal complaints or appeal process",}
        ],
        inputs: [
          {
            name: "other_formal_accessed",
            data : this.props.data ? this.props.data["other_formal_accessed"]: "",
          }
        ]         
      },
      {
        index : 8,
        data : this.props.data,
        texts: [
          {title: "H.", 
          width: "8%"},  
          {title:"Other external help, such as mediation, conciliation, arbitration",}
        ],
        inputs: [
          {
            name: "other_external_accessed",
            data : this.props.data ? this.props.data["other_external_accessed"]: "",
          }
        ]         
      },
      {
        index : 9,
        data : this.props.data,
        texts: [
          {title: "X.", 
          width: "8%"},  
          {title:"Other person or organization",}
        ],
        inputs: [
          {
            name: "other_accessed",
            data : this.props.data ? this.props.data["other_accessed"]: "",
          }
        ]         
      },
    ]

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-12">
            <h4>2.	Access to dispute resolution mechanism(s) for most selected dispute, by type of mechanism (Question AJ4 – number s of ‘yes’)</h4>
          </div>
          <div className="row">
            <div className="col-sm-8 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >

                <InputTable 
                  headers={headers}
                  rows = {tableData}
                  handleTableChange={this.handleTableChange}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1633State;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(DisputeResolutionTable);

export { connectedIndicatorData as DisputeResolutionTable };
