import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1671cActions } from "../../../../../../_actions";
import { TextInput, NumberInput } from "carbon-components-react";


class RowRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.getRatiosColumns = this.getRatiosColumns.bind(this);
    this.getRatiosOfYoung = this.getRatiosOfYoung.bind(this);

  }

  handleTableChange(e) {
    // console.log("name", e.target.name, e.target.value)
    this.props.handleTableChange(e.target.name,e.target.value);
  }

  getRatiosColumns(index, name, denominator) {

    const male = this.props.data.data[index]["male"] ? parseFloat(this.props.data.data[index]["male"]) : 0
    const female = this.props.data.data[index]["female"] ? parseFloat(this.props.data.data[index]["female"]) : 0
    const val = this.props.data.data[index][name] >= 0 ? parseFloat(this.props.data.data[index][name]) : null;



    if (
      this.props.data && 
      this.props.data.data && 
      this.props.data.data[index][name] >=0 && 
      this.props.data.ratios &&
      this.props.data.ratios.length > 0 &&
      denominator && denominator > 0 &&
      (male + female) > 0 &&
      (
        this.props.data.ratios[index][name] === null || 
            (parseFloat(this.props.data.ratios[index][name]).toFixed(2) !== (this.props.data.data[index][name]/(female + male)*100/denominator).toFixed(2)))
      ) 
      {
        console.log("Update field");
          this.props.updateRatiosField(index, name, ((this.props.data.data[index][name]/(female + male))*100/denominator).toFixed(2))
      }
    return (male + female) > 0 && val >= 0 &&
           denominator && 
           denominator > 0 ?((parseFloat(this.props.data.data[index][name])/(female + male))*100/denominator).toFixed(2): '';
  }
  
  getRatiosOfYoung(index) {

    const male = this.props.data.data[index]["male"] ? parseFloat(this.props.data.data[index]["male"]) : 0
    const female = this.props.data.data[index]["female"] ? parseFloat(this.props.data.data[index]["female"]) : 0
    const age_under_35 = this.props.data.data[index]["age_under_35"] ? parseFloat(this.props.data.data[index]["age_under_35"]) : 0;
    const between_35_44 = this.props.data.data[index]["between_35_44"] ? parseFloat(this.props.data.data[index]["between_35_44"]) : 0;

    if (this.props.data && 
      this.props.data.ratios &&
      this.props.data.ratios.length > 0 &&
      this.props.data["age_under_45_ratio"] &&
      this.props.data["age_under_45_ratio"] > 0 &&
      (male + female) > 0 &&
      (age_under_35 + 
        between_35_44 > 0) && 
      (this.props.data.ratios[index]["age_under_44"] === null ||
            (parseFloat(this.props.data.ratios[index]["age_under_44"]).toFixed(2) !== ((((age_under_35 + 
              between_35_44))/(female + male))*100/this.props.data["age_under_45_ratio"]).toFixed(2)))) {
                
                this.props.updateRatiosField(index, "age_under_44", ((((age_under_35 + 
                  between_35_44))/(female + male))*100/this.props.data["age_under_45_ratio"]).toFixed(2));     
          
      }

      return (male + female) > 0 && (age_under_35 + 
      between_35_44 > 0) && this.props.data["age_under_45_ratio"] ? 
      (parseFloat(age_under_35 + 
                between_35_44)/
                parseFloat(male + 
                female)*100/parseFloat(this.props.data["age_under_45_ratio"])).toFixed(2) : ""
  }


  render() {
    return (
      <React.Fragment>
        <tr>
          <td style={{background:this.props.title.indexOf("Total") !== -1 ? "#FFC000": "white"}}>
            {this.props.title}
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:female:judges`}
              name={`${this.props.index}:proportion:female:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index, "female", 50)
              }
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:female:registrars`}
              name={`${this.props.index}:proportion:female:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index+4, "female", 50)}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:young:judges`}
              name={`${this.props.index}:proportion:young:female`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getRatiosOfYoung(this.props.index)}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:female:registrars`}
              name={`${this.props.index}:proportion:female:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getRatiosOfYoung(this.props.index+4)}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:disabled:judges`}
              name={`${this.props.index}:proportion:disabled:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index, "disabled", this.props.data["disability_ratio"])}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:disabled:registrars`}
              name={`${this.props.index}:proportion:disabled:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index+4, "disabled", this.props.data["disability_ratio"])}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_a:judges`}
              name={`${this.props.index}:proportion:group_a:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index, "group_a", this.props.data["group_a_ratio"])}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_a:registrars`}
              name={`${this.props.index}:proportion:group_a:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index+4, "group_a", this.props.data["group_a_ratio"])}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_b:judges`}
              name={`${this.props.index}:proportion:group_b:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index, "group_b", this.props.data["group_b_ratio"])}

            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_b:registrars`}
              name={`${this.props.index}:proportion:group_b:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index+4, "group_b", this.props.data["group_b_ratio"])}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_c:judges`}
              name={`${this.props.index}:proportion:group_c:judges`}
              // className="input-1671b"
              labelText=""
              // onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index, "group_c", this.props.data["group_c_ratio"])}
            />
          </td>
          <td>
            <TextInput
              id={`${this.props.index}:proportion:group_c:registrars`}
              name={`${this.props.index}:proportion:group_c:registrars`}
              // className="input-1671b"
              labelText=""
              onChange={this.handleTableChange}
              value={this.getRatiosColumns(this.props.index+4, "group_c", this.props.data["group_c_ratio"])}
            />
          </td>        
          </tr>
      </React.Fragment>
    )
  }

}

class RatiosTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleRatiosChange = this.handleRatiosChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
    this.updateDenominator = this.updateDenominator.bind(this);

  }

  handleRatiosChange(index, name, value) {
    let { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateRatiosField(index, name, value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  updateDenominator(e) {
    let { dispatch } = this.props;

    dispatch(
      sdg1671cActions.updateMetadataField(e.target.name, e.target.value)
    );
  }

  
  
  render() {

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-8">
            <h4>Step 3: Ratios compared to overall working-age population</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >
                <table className="table table-bordered table-1671b">
                  <thead style={{fontSize:"0.9vw"}}>
                    <tr>
                      <th rowSpan="2" style={{ width: "18%" }}></th>
                      <th colSpan="2">Female representation ratios: Proportion of female judges and registrars / Proportion of women in the working-age population</th>
                      <th colSpan="2">‘Youth’ representation ratios: Proportion of ‘young’ judges and registrars aged 44 and below / Proportion of the working-age population aged above the eligibility age and below 45</th>
                      <th colSpan="2">Disability representation ratios: Proportion of judges and registrars with a disability / Proportion of persons with a disability in the working-age population</th>
                      <th colSpan="2">Population group(s) representation ratios: Proportion of judges and registrars in population group A / Proportion of persons in given population group in the working-age population</th>
                      <th colSpan="2">Population group(s) representation ratios: Proportion of judges and registrars in population group B / Proportion of persons in given population group in the working-age population</th>
                      <th colSpan="2">Population group(s) representation ratios: Proportion of judges and registrars in population group C / Proportion of persons in given population group in the working-age population</th>
                    </tr>
                    <tr>
                      <th>Denominator</th>
                      <th>50%</th>
                      <th>Denominator</th>
                      <th>
                      <NumberInput
                          id="age_under_45_ratio"
                          name="age_under_45_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data["age_under_45_ratio"]
                              ? this.props.data["age_under_45_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>Denominator</th>
                      <th>
                      <NumberInput
                          id="disability_ratio"
                          name="disability_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data["disability_ratio"]
                              ? this.props.data["disability_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>Denominator</th>
                      <th>
                      <NumberInput
                          id="group_a_ratio"
                          name="group_a_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data["group_a_ratio"]
                              ? this.props.data["group_a_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>Denominator</th>
                      <th>
                      <NumberInput
                          id="group_b_ratio"
                          name="group_b_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data["group_b_ratio"]
                              ? this.props.data["group_b_ratio"]
                              : 0
                          }
                        />
                      </th>
                      <th>Denominator</th>
                      <th>
                      <NumberInput
                          id="group_c_ratio"
                          name="group_c_ratio"
                          // className="input-1671b"
                          label=""
                          onChange={this.updateDenominator}
                          value={
                            this.props.data && this.props.data["group_c_ratio"]
                              ? this.props.data["group_c_ratio"]
                              : 0
                          }
                        />
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Judges</th>
                      <th>Registrars</th>
                      <th>Judges</th>
                      <th>Registrars</th>
                      <th>Judges</th>
                      <th>Registrars</th>
                      <th>Judges</th>
                      <th>Registrars</th>
                      <th>Judges</th>
                      <th>Registrars</th>
                      <th>Judges</th>
                      <th>Registrars</th>
                    </tr>

                  </thead>
                  <tbody style={{fontSize:"0.95vw"}}>
                    <RowRenderer
                      index={2}
                      data={this.props.data}
                      title={"Constitutional / supreme courts"}
                      updateRatiosField={this.handleRatiosChange}
                      />
                    <RowRenderer
                      title={"Higher-level courts"}
                      index={0}
                      data={this.props.data}
                      updateRatiosField={this.handleRatiosChange}
                      />
                    <RowRenderer
                      title={"Lower-level courts"}
                      index={1}
                      data={this.props.data}
                      updateRatiosField={this.handleRatiosChange}
                      />
                      <RowRenderer
                      title={"Total (across all levels of courts)"}
                      index={3}
                      data={this.props.data}
                      updateRatiosField={this.handleRatiosChange}
                      />  
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1671cState;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(RatiosTable);

export { connectedIndicatorData as RatiosTable };
