import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1662Actions } from "../../../../../_actions/sdg1662.actions";
import { Link } from "react-router-dom";
import { HealthcareData } from "./data/HealthcareData";
import { GovernmentData } from "./data/GovernmentData";
import { PrimaryEducationData } from "./data/PrimaryEducationData";
import { SecondaryEducationData } from "./data/SecondaryEducationData";

class IndicatorData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  getTableRows() {
    return [
      {
        isHead: false,
        title: "Total / National:",
        name: "total",
        number: 8
      },
      {
        isHead: true,
        title: "Sex",
        span: "9"
      },
      {
        isHead: false,
        title: "Male",
        name: "male",
        number: 8
      },
      {
        isHead: false,
        title: "Female",
        name: "female",
        number: 8
      },
      {
        isHead: true,
        title: "Urban/rural",
        span: "9"
      },
      {
        isHead: false,
        title: "Urban",
        name: "urban",
        number: 8
      },
      {
        isHead: false,
        title: "Rural",
        name: "rural",
        number: 8
      },
      {
        isHead: true,
        title: "Income or expenditure quintiles",
        span: "9"
      },
      {
        isHead: false,
        title: "Quintile 1",
        name: "quintile_1",
        number: 8
      },
      {
        isHead: false,
        title: "Quintile 2",
        name: "quintile_2",
        number: 8
      },
      {
        isHead: false,
        title: "Quintile 3",
        name: "quintile_3",
        number: 8
      },
      {
        isHead: false,
        title: "Quintile 4",
        name: "quintile_4",
        number: 8
      },
      {
        isHead: false,
        title: "Quintile 5",
        name: "quintile_5",
        number: 8
      },
      {
        isHead: true,
        title: "Disability status",
        span: "9"
      },
      {
        isHead: false,
        title: "Disabled",
        name: "disabled",
        number: 8
      },
      {
        isHead: false,
        title: "Not disabled",
        name: "not_disabled",
        number: 8
      },
      {
        isHead: true,
        title: "Nationally relevant population groups",
        span: "9"
      },
      {
        isHead: false,
        title: "Population group A",
        name: "population_a",
        number: 8
      },
      {
        isHead: false,
        title: "Population group B",
        name: "population_b",
        number: 8
      },
      {
        isHead: false,
        title: "Population group C",
        name: "population_c",
        number: 8
      },
      {
        isHead: true,
        title: "Age groups",
        span: "9"
      },
      {
        isHead: false,
        title: "Below 25",
        name: "age_below_25",
        number: 8
      },
      {
        isHead: false,
        title: "25-34",
        name: "age_25_34",
        number: 8
      },
      {
        isHead: false,
        title: "35-44",
        name: "age_35_44",
        number: 8
      },
      {
        isHead: false,
        title: "45-54",
        name: "age_45_54",
        number: 8
      },
      {
        isHead: false,
        title: "55-64",
        name: "age_55_64",
        number: 8
      },
      {
        isHead: false,
        title: "Over 65",
        name: "age_over_65",
        number: 8
      }
    ];
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    dispatch(
      sdg1662Actions.updateDataField(parseInt(target[0]), target[1], value)
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  render() {
    let info =
      !this.props.data.id || this.props.data.id === null ? (
        <div className="row">
          <div className="col-sm-12" style={{ marginBottom: "15px" }}>
            * Please save <Link onClick={this.goToMetadata}>metadata</Link>{" "}
            before entering indicator data.
          </div>
        </div>
      ) : null;

    return (
      <React.Fragment>
        <div className="box-header with-border">
          <div className="col-sm-8">
            <h3>Data fields - please provide weighted estimates</h3>
          </div>
        </div>
        <div className="box-body">
          {info}
          <div className="row">
            <HealthcareData />
          </div>
          <div className="row">
          <div className="box-body">
          <div className="col-sm-12">
            <h4>2. Education services</h4>
          </div>
          </div>
          </div>
          <div className="row">
            <PrimaryEducationData />
          </div>
          <div className="row">
            <SecondaryEducationData />
          </div>                  
          <div className="row">
            <GovernmentData />
          </div>           
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data, error } = state.sdg1662State;

  return {
    data,
    error
  };
}

const connectedIndicatorData = connect(mapStateToProps)(IndicatorData);

export { connectedIndicatorData as IndicatorData };
