import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../../_actions";
import InputTable from "../../../../../common/table/InputTable";


class DisputeMechanismAccessTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    let target = name.split(":");

    // console.log("Target", target);

    dispatch(
      sdg1633Actions.updateResolutionsField(parseInt(target[0]), target[1], parseInt(value))
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  
  render() {

    let headers = [
      {title: "", width: "2%"},
      {title: "Dispute types", width: "18%"},
      {title: "Court or tribunal"},
      {title: `Police (or other law enforcement`},
      {title: "A government or municipal office or other formal designated authority or agency"},
      {title: "Religious leader or authority"},
      {title: `Community leader or authority (such as village elder, or local leader)`},
      {title: "Lawyer, solicitor, paralegal"},
      {title: "Other formal complaints or appeal process"},
      {title: "Other external help, such as mediation, conciliation, arbitration"},
      {title: "Other person or organization"},
      {title: "Neither"},
    ]

    let tableData = [
      {
        index : 1,
        data : this.props.data,
        texts: [
          {
            title: "1",
          },  
          {
            title:"Problems with LAND, OR BUYING AND SELLING PROPERTY",
          }
        ],
        inputs: [
          {
            name: "0:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[0]["property_problems"]: "",

          },
          {
            name: "1:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[1]["property_problems"]: "",
          },
          {
            name: "2:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[2]["property_problems"]: "",
          },
          {
            name: "3:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[3]["property_problems"]: "",
          },
          {
            name: "4:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[4]["property_problems"]: "",
          },
          {
            name: "5:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[5]["property_problems"]: "",
          },
          {
            name: "6:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[6]["property_problems"]: "",
          },
          {
            name: "7:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[7]["property_problems"]: "",
          },
          {
            name: "8:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[8]["property_problems"]: "",
          },
          {
            name: "9:property_problems",
            data : this.props.data? this.props.data.despute_resolutions[9]["property_problems"]: "",
          },

        ]       
      },
      {
        index : 2,
        data : this.props.data,
        texts: [
          {
            title: "2",
          },  
          {
            title:"Issues with HOUSING",
          }
        ],
        inputs: [
          {
            name: "0:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[0]["housing_issues"]: "",
          },
          {
            name: "1:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[1]["housing_issues"]: "",
          },        
          {
            name: "2:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[2]["housing_issues"]: "",
          },
          {
            name: "3:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[3]["housing_issues"]: "",
          },
          {
            name: "4:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[4]["housing_issues"]: "",
          },
          {
            name: "5:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[5]["housing_issues"]: "",
          },
          {
            name: "6:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[6]["housing_issues"]: "",
          },
          {
            name: "7:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[7]["housing_issues"]: "",
          },
          {
            name: "8:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[8]["housing_issues"]: "",
          },
          {
            name: "9:housing_issues",
            data : this.props.data? this.props.data.despute_resolutions[9]["housing_issues"]: "",
          },
        ]         
      },
      {
        index : 3,
        texts: [
          {
            title: "3",
          },  
          {
            title:"Trying to resolve FAMILY ISSUES",
          }
        ],
        inputs: [
          {
            name: "0:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[0]["family_issues"]: "",
          },
          {
            name: "1:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[1]["family_issues"]: "",
          },        
          {
            name: "2:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[2]["family_issues"]: "",
          },
          {
            name: "3:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[3]["family_issues"]: "",
          },
          {
            name: "4:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[4]["family_issues"]: "",
          },
          {
            name: "5:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[5]["family_issues"]: "",
          },
          {
            name: "6:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[6]["family_issues"]: "",
          },
          {
            name: "7:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[7]["family_issues"]: "",
          },
          {
            name: "8:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[8]["family_issues"]: "",
          },
          {
            name: "9:family_issues",
            data : this.props.data? this.props.data.despute_resolutions[9]["family_issues"]: "",
          },
        ]         
      },
      {
        index : 4,
        data : this.props.data,
        texts: [
          {
            title: "4",
          },  
          {
            title:"Seeking compensation for INJURIES OR ILLNESS",
          }
        ],
        inputs: [
          {
            name: "0:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[0]["injuries_illness_compensation"]: "",
          },
          {
            name: "1:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[1]["injuries_illness_compensation"]: "",
          },        
          {
            name: "2:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[2]["injuries_illness_compensation"]: "",
          },
          {
            name: "3:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[3]["injuries_illness_compensation"]: "",
          },
          {
            name: "4:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[4]["injuries_illness_compensation"]: "",
          },
          {
            name: "5:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[5]["injuries_illness_compensation"]: "",
          },
          {
            name: "6:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[6]["injuries_illness_compensation"]: "",
          },
          {
            name: "7:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[7]["injuries_illness_compensation"]: "",
          },
          {
            name: "8:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[8]["injuries_illness_compensation"]: "",
          },
          {
            name: "9:injuries_illness_compensation",
            data : this.props.data? this.props.data.despute_resolutions[9]["injuries_illness_compensation"]: "",
          },
        ]         
      },
      {
        index : 5,
        data : this.props.data,
        texts: [
          {
            title: "5",
          },  
          {
            title:"Problems with EMPLOYMENT OR LABOR",
          }
        ],
        inputs: [
          {
            name: "0:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[0]["employment_labor_problems"]: "",
          },
          {
            name: "1:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[1]["employment_labor_problems"]: "",
          },        
          {
            name: "2:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[2]["employment_labor_problems"]: "",
          },
          {
            name: "3:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[3]["employment_labor_problems"]: "",
          },
          {
            name: "4:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[4]["employment_labor_problems"]: "",
          },
          {
            name: "5:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[5]["employment_labor_problems"]: "",
          },
          {
            name: "6:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[6]["employment_labor_problems"]: "",
          },
          {
            name: "7:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[7]["employment_labor_problems"]: "",
          },
          {
            name: "8:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[8]["employment_labor_problems"]: "",
          },
          {
            name: "9:employment_labor_problems",
            data : this.props.data? this.props.data.despute_resolutions[9]["employment_labor_problems"]: "",
          },
        ]         
      },
      {
        index : 6,
        data : this.props.data,
        texts: [
          {
            title: "6",
          },  
          {
            title:"GOVERNMENT AND PUBLIC SERVICES OTHER THAN PAYMENTS",
          }
        ],
        inputs: [
          {
            name: "0:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[0]["other_services_problems"]: "",
          },
          {
            name: "1:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[1]["other_services_problems"]: "",
          },        
          {
            name: "2:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[2]["other_services_problems"]: "",
          },
          {
            name: "3:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[3]["other_services_problems"]: "",
          },
          {
            name: "4:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[4]["other_services_problems"]: "",
          },
          {
            name: "5:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[5]["other_services_problems"]: "",
          },
          {
            name: "6:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[6]["other_services_problems"]: "",
          },
          {
            name: "7:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[7]["other_services_problems"]: "",
          },
          {
            name: "8:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[8]["other_services_problems"]: "",
          },
          {
            name: "9:other_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[9]["other_services_problems"]: "",
          },
        ]         
      },
      {
        index : 7,
        data : this.props.data,
        texts: [
          {
            title: "7",
          },  
          {
            title:"Problems with other GOODS AND SERVICES",
          }
        ],
        inputs: [
          {
            name: "0:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[0]["goods_services_problems"]: "",
          },
          {
            name: "1:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[1]["goods_services_problems"]: "",
          },        
          {
            name: "2:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[2]["goods_services_problems"]: "",
          },
          {
            name: "3:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[3]["goods_services_problems"]: "",
          },
          {
            name: "4:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[4]["goods_services_problems"]: "",
          },
          {
            name: "5:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[5]["goods_services_problems"]: "",
          },
          {
            name: "6:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[6]["goods_services_problems"]: "",
          },
          {
            name: "7:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[7]["goods_services_problems"]: "",
          },
          {
            name: "8:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[8]["goods_services_problems"]: "",
          },
          {
            name: "9:goods_services_problems",
            data : this.props.data? this.props.data.despute_resolutions[9]["goods_services_problems"]: "",
          },
        ]         
      },
      {
        index : 8,
        data : this.props.data,
        texts: [
          {
            title: "8",
          },  
          {
            title:"Problems with GOVERNMENT PAYMENTS",
          }
        ],
        inputs: [
          {
            name: "0:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[0]["goverment_payments_problems"]: "",
          },
          {
            name: "1:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[1]["goverment_payments_problems"]: "",
          },        
          {
            name: "2:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[2]["goverment_payments_problems"]: "",
          },
          {
            name: "3:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[3]["goverment_payments_problems"]: "",
          },
          {
            name: "4:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[4]["goverment_payments_problems"]: "",
          },
          {
            name: "5:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[5]["goverment_payments_problems"]: "",
          },
          {
            name: "6:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[6]["goverment_payments_problems"]: "",
          },
          {
            name: "7:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[7]["goverment_payments_problems"]: "",
          },
          {
            name: "8:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[8]["goverment_payments_problems"]: "",
          },
          {
            name: "9:goverment_payments_problems",
            data : this.props.data? this.props.data.despute_resolutions[9]["goverment_payments_problems"]: "",
          },
        ]         
      },
      {
        index : 9,
        data : this.props.data,
        texts: [
          {
            title: "9",
          },  
          {
            title:"Issues with MONEY, DEBT OR FINANCIAL SERVICES",
          }
        ],
        inputs: [
          {
            name: "0:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[0]["financial_services_issues"]: "",
          },
          {
            name: "1:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[1]["financial_services_issues"]: "",
          },        
          {
            name: "2:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[2]["financial_services_issues"]: "",
          },
          {
            name: "3:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[3]["financial_services_issues"]: "",
          },
          {
            name: "4:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[4]["financial_services_issues"]: "",
          },
          {
            name: "5:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[5]["financial_services_issues"]: "",
          },
          {
            name: "6:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[6]["financial_services_issues"]: "",
          },
          {
            name: "7:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[7]["financial_services_issues"]: "",
          },
          {
            name: "8:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[8]["financial_services_issues"]: "",
          },
          {
            name: "9:financial_services_issues",
            data : this.props.data? this.props.data.despute_resolutions[9]["financial_services_issues"]: "",
          },
        ]         
      },
      {
        index : 10,
        data : this.props.data,
        texts: [
        {
          title: "10",
        },  
        {
          title:"ENVIRONMENTAL Issues",
        }
        ],
        inputs: [
          {
            name: "0:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[0]["environmental_issues"]: "",
          },
          {
            name: "1:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[1]["environmental_issues"]: "",
          },        
          {
            name: "2:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[2]["environmental_issues"]: "",
          },
          {
            name: "3:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[3]["environmental_issues"]: "",
          },
          {
            name: "4:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[4]["environmental_issues"]: "",
          },
          {
            name: "5:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[5]["environmental_issues"]: "",
          },
          {
            name: "6:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[6]["environmental_issues"]: "",
          },
          {
            name: "7:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[7]["environmental_issues"]: "",
          },
          {
            name: "8:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[8]["environmental_issues"]: "",
          },
          {
            name: "9:environmental_issues",
            data : this.props.data? this.props.data.despute_resolutions[9]["environmental_issues"]: "",
          },
        ]         
      },
      {
        index : 11,
        data : this.props.data,
        texts: [
          {
            title: "11",
          },  
          {
            title:"NEIGHBORHOOD disputes",
          }
        ],
        inputs: [
          {
            name: "0:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[0]["neighborhood_disputes"]: "",
          },
          {
            name: "1:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[1]["neighborhood_disputes"]: "",
          },        
          {
            name: "2:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[2]["neighborhood_disputes"]: "",
          },
          {
            name: "3:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[3]["neighborhood_disputes"]: "",
          },
          {
            name: "4:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[4]["neighborhood_disputes"]: "",
          },
          {
            name: "5:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[5]["neighborhood_disputes"]: "",
          },
          {
            name: "6:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[6]["neighborhood_disputes"]: "",
          },
          {
            name: "7:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[7]["neighborhood_disputes"]: "",
          },
          {
            name: "8:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[8]["neighborhood_disputes"]: "",
          },
          {
            name: "9:neighborhood_disputes",
            data : this.props.data? this.props.data.despute_resolutions[9]["neighborhood_disputes"]: "",
          },
        ]         
      },                                                
    ]

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-12">
            <h4>3.	Access to the various dispute resolution mechanism(s) for dispute selected, by type of dispute (number of people who used the specific type of resolution mechanism(s) as per AJ4 versus the dispute selected as per AJ1 (AJ2, if multiple types were confirmed in AJ1)</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >

                <InputTable 
                  headers={headers}
                  rows = {tableData}
                  handleTableChange={this.handleTableChange}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1633State;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(DisputeMechanismAccessTable);

export { connectedIndicatorData as DisputeMechanismAccessTable };
