import React, { Component } from "react";

import { connect } from "react-redux";

import { sdg1633Actions } from "../../../../../../_actions";
import InputTable from "../../../../../common/table/InputTable";


class RespondentsDisputeTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      error: props.error
    };

    this.handleTableChange = this.handleTableChange.bind(this);
    this.goToMetadata = this.goToMetadata.bind(this);
  }

  handleTableChange(name, value) {
    let { dispatch } = this.props;

    dispatch(
      sdg1633Actions.updateMetadataField(name, parseInt(value))
    );
  }

  goToMetadata() {
    this.props.onPrevious();
  }

  
  render() {

    //console.log("props", this.props.data);

    let headers = [
      {title: "", width: "8%"},
      {title: "Dispute types", width: "75%"},
      {title: "Number of people confirming having experienced this type of dispute over past two years"}
    ]

    let tableData = [
      {
        index : 1,
        texts: [
          {
            title: "1", 
          },  
          {
            title:"Problems with LAND, OR BUYING AND SELLING PROPERTY",
          }
        ],
        inputs: [
          {
            name: "property_problems_confirmed",
            data : this.props.data ? this.props.data["property_problems_confirmed"]: "",
          }
        ]       
      },
      {
        index : 2,
        texts: [
          {
            title: "2", 
          },  
          {
            title:"Issues with HOUSING",
          }
        ],
        inputs: [
          {
            name: "housing_issues_confirmed",
            data : this.props.data? this.props.data["housing_issues_confirmed"]: "",
          }
        ]         
      },
      {
        index : 3,
        texts: [
          {
            title: "3",
          },  
          {
            title:"Trying to resolve FAMILY ISSUES",
          }
        ],
        inputs: [
          {
            name: "family_issues_confirmed",
            data : this.props.data? this.props.data["family_issues_confirmed"]: "",
          }
        ]         
      },
      {
        index : 4,
        texts: [
          {
            title: "4",
          },  
          {
            title:"Seeking compensation for INJURIES OR ILLNESS",
          }
        ],
        inputs: [
          {
            name: "injuries_illness_compensation_confirmed",
            data : this.props.data? this.props.data["injuries_illness_compensation_confirmed"]: "",
          }
        ]         
      },
      {
        index : 5,
        texts: [
          {
            title: "5",
          },  
          {
            title:"Problems with EMPLOYMENT OR LABOR",
          }
        ],
        inputs: [
          {
            name: "employment_labor_problems_confirmed",
            data : this.props.data? this.props.data["employment_labor_problems_confirmed"]: "",
          }
        ]         
      },
      {
        index : 6,
        texts: [
          {
            title: "6",
          },  
          {
            title:"GOVERNMENT AND PUBLIC SERVICES OTHER THAN PAYMENTS",
          }
        ],
        inputs: [
          {
            name: "other_services_problems_confirmed",
            data : this.props.data? this.props.data["other_services_problems_confirmed"]: "",
          }
        ]         
      },
      {
        index : 7,
        texts: [
          {
            title: "7",
          },  
          {
            title:"Problems with other GOODS AND SERVICES",
          }
        ],
        inputs: [
          {
            name: "goods_services_problems_confirmed",
            data : this.props.data? this.props.data["goods_services_problems_confirmed"]: "",
          }
        ]         
      },
      {
        index : 8,
        texts: [
          {
            title: "8",
          },  
          {
            title:"Problems with GOVERNMENT PAYMENTS",
          }
        ],
        inputs: [
          {
            name: "goverment_payments_problems_confirmed",
            data : this.props.data? this.props.data["goverment_payments_problems_confirmed"]: "",
          }
        ]         
      },
      {
        index : 9,
        texts: [
          {
            title: "9",
          },  
          {
            title:"Issues with MONEY, DEBT OR FINANCIAL SERVICES",
          }
        ],
        inputs: [
          {
            name: "financial_services_issues_confirmed",
            data : this.props.data? this.props.data["financial_services_issues_confirmed"]: "",
          }
        ]         
      },
      {
        index : 10,
        texts: [
        {
          title: "10",
        },  
        {
          title:"ENVIRONMENTAL Issues",
        }
        ],
        inputs: [
          {
            name: "environmental_issues_confirmed",
            data : this.props.data? this.props.data["environmental_issues_confirmed"]: "",
          }
        ]         
      },
      {
        index : 11,
        texts: [
          {
            title: "11",
          },  
          {
            title:"NEIGHBORHOOD disputes",
          }
        ],
        inputs: [
          {
            name: "neighborhood_disputes_confirmed",
            data : this.props.data? this.props.data["neighborhood_disputes_confirmed"]: "",
          }
        ]         
      },                                                
    ]

    return (
      <React.Fragment>
        <div className="box-body">
          <div className="col-sm-12">
            <h4>1.	Number of people who experienced a dispute during the past two years, by type of dispute (Question AJ1 – Number of ‘yes’)</h4>
          </div>
          <div className="row">
            <div className="col-sm-8 adaptive-table">
              <fieldset
                disabled={this.props.data && (!this.props.data.id || this.props.data.id === null)}
              >

                <InputTable 
                  headers={headers}
                  rows = {tableData}
                  handleTableChange={this.handleTableChange}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.sdg1633State;

  return {
    data
  };
}

const connectedIndicatorData = connect(mapStateToProps)(RespondentsDisputeTable);

export { connectedIndicatorData as RespondentsDisputeTable };
